import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "../styles/components/Footer.css";
import footerLogo from "../assets/footerImg/footerLogo.svg";
import linkedinIcon from "../assets/socialcons/LinkedIn.png"
import twitterIcon from "../assets/socialcons/Twitter.png"
import instagramIcon from "../assets/socialcons/Instagram.png"
import facebookIcon from "../assets/socialcons/Facebook.png"
// importing custom hooks 
import useScrollAnimation from '../hooks/UseScrollAnimation';

function Footer() {
  const faceUpAnime = useScrollAnimation('fade-up');
  return (
    <footer ref={faceUpAnime}>
      <div className="footer-div">
        <section className="download-app-sec">
          <div className="flex flex-col align-center">
            <div className="footer-logo-image">
              <LazyLoadImage effect="blur" src={footerLogo} alt={footerLogo} width="100%" height="100%"/>
            </div>
              <span className="footer-logo-name | flex justify-content-center  flex-col ">
              <h1 className="mt-50">MYeKIGAI</h1>
            </span>
          </div>
        </section>

        <section className="footer-nav-sec">
          <div className="footer-nav-div | block p-50 flex two-col-grid">
              <nav>
                <ul>
                  <li>
                    <Link
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services"
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/partner"
                    >
                      Partner With Us
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/blogs"
                    >
                      Blog
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/contact"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
              <nav>
                <ul>
                <li>
                    <Link
                      to="/"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  
                <li>
                    <Link
                      to="/"
                    >
                      Consumer Terms
                    </Link>
                  </li>
                  
                <li>
                    <Link
                      to="/"
                    >
                      Buddy Terms
                    </Link>
                  </li>
                  
                </ul>
              </nav>
          </div>
        </section>

        <section className="social-icons-sec">
          <div className="social-icons-div">
            <h3 className="footer-heading text-right">Follow Us</h3>
            <div className="footer-icons-div | mt-200">
              <ul>
                <li>
                  <Link to="https://www.linkedin.com/company/myekigai-ppl/">
                    <LazyLoadImage effect="blur" src={linkedinIcon} alt=".." />
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/myekigai">
                    <LazyLoadImage effect="blur" src={twitterIcon} alt=".." />
                  </Link>
                </li>
                <li>
                  <Link to="https://instagram.com/myekigai?igshid=MjEwN2IyYWYwYw== ">
                    <LazyLoadImage effect="blur" src={instagramIcon} alt=".." />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <LazyLoadImage effect="blur" src={facebookIcon} alt=".." />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div>
        <h1 className="footer-copyright">© 2023 MYeKIGAI Profound Pvt. Ltd.</h1>
      </div>
    </footer>
  );
}

export default Footer;
