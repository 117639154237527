import React, { useState ,useEffect} from 'react';
import PropType from 'prop-types'
import "../styles/components/contact.css";
import { Store } from 'react-notifications-component';
import env from '../assets/credentials/env'
import { json } from 'react-router-dom';
// -------------helper functions for jsx ------------
// const renderInputs = (row_count)=>{
//   if(row_count === 1){
//     return (
//       <>
//         <div className='contact-inputs | flex w-100 gap-100'>
//           <input title="Valid email address" type="email" name="email" placeholder="Email Id *" required />
//           <input title="Short heading" type="text" name="subject" placeholder="Subject *" required />
//           <input title="Only numbers" type="number" name="mobile" placeholder="Mobile no. *" pattern='/d{10,12}' required />
//         </div>
//       </>
//     )  
//   }else if(row_count === 2){
//     return (
//       <>
//         <div className='contact-inputs | flex w-100 gap-100'>
//           <input title="Only numbers" type="number" name="mobile" placeholder="Mobile no. *" pattern='/d{10,12}' required />
//           <input title="Valid email address" type="email" name="email" placeholder="Email Id *" required />
//         </div>
//           <input title="Short heading" type="text" name="subject" placeholder="Subject *" required />
//       </>
//     )  
//   }else{
//     return (
//       <>
//         <input title="Valid email address" type="email" name="email" placeholder="Email Id *" required />
//         <input title="Only numbers" type="number" name="mobile" placeholder="Mobile no. *" pattern='/d{10,12}' required />
//         <input title="Short heading" type="text" name="subject" placeholder="Subject *" required />
//       </>
//     )
//   }
// }
const ContactForm = ({row_count, textFieldText="Enter Your Comment",formTitle="",buttonText="Submit"}) => {
  // ----------Media query to change contact us input to 3 rows in mobile devices --------
  const [rowCount ,setRowCount] = useState(row_count)
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 866px)");
    mediaQuery.matches?setRowCount(3):setRowCount(row_count)
    const handleMediaQueryChange = (event) => {
      event.matches?setRowCount(3):setRowCount(row_count)
    }
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, [row_count,rowCount]);

  const handleSubmit = (event) => {
    Store.addNotification({
      title: "Please wait",
      message: "While adding to contact list.",
      type: "info",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeInRight"],
      animationOut: ["animate__animated", "animate__fadeOutRight"],
      dismiss: {
        duration: 3000,
        timingFunction: 'ease-out',
        showIcon:true,
        click: false
      },
    });
    event.preventDefault();
    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      mobile_number: event.target.mobile.value,
      comment: event.target.comment.value
    };
   
    fetch(`${env.API_END_POINT}/contactus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization":env.API_ACCESS_TOKEN

      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        console.log(response,json())
          if (response.status === 201) {
            Store.addNotification({
              title: "Successfully ",
              message: "Form Submitted",
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeInRight"],
              animationOut: ["animate__animated", "animate__fadeOutRight"],
              dismiss: {
                duration: 3000,
                timingFunction: 'ease-out',
                showIcon:true,
                click: false
              },
            });
            event.target.reset();
          }else{
            Store.addNotification({
              title: "Unsuccessful ", 
              message: "Try refreshing the page",
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeInRight"],
              animationOut: ["animate__animated", "animate__fadeOutRight"],
              dismiss: {
                duration: 3000,
                timingFunction: 'ease-out',
                showIcon:true,
                click: false
              },
            });
          }
          })
      .catch(error => {
        Store.addNotification({
          title: "Unsuccessfull ",
          message: "Unable to connect with server",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeInRight"],
          animationOut: ["animate__animated", "animate__fadeOutRight"],
          dismiss: {
            duration: 2000,
            timingFunction: 'ease-out',
            showIcon:true,
            click: false
          },
        });
      });
  };

  return (
    <>
      {formTitle&&
      <h2 className='contactFormTitle'>{formTitle}</h2>
      }
    <form id="contactus" className='contact-us-form | flex align-center justify-content-center flex-col w-100 gap-100' onSubmit={handleSubmit}>
      <input title="Fill your full name" type="text" name="name" placeholder="Name *" required />
      {/* {rowCount} */}
      {/* {renderInputs(rowCount)} */}
      <input title="Only numbers" type="number" name="mobile" placeholder="Mobile no. *" pattern='/d{10,12}' required />
      <input title="Valid email address" type="email" name="email" placeholder="Email Id *" required />
      <textarea name="comment" placeholder={textFieldText} rows="4" cols="50" required></textarea>
      <button form = "contactus" className="contact-us-btn | btn text-center flex align-center justify-content-center" type="submit">{buttonText}</button>
  </form>
  </>
  
  );
};

export default ContactForm;
ContactForm.propTypes = {
  row_count: PropType.number,
  textFieldText: PropType.string,
  formTitle: PropType.string,
  buttonText: PropType.string
}