// import { useState ,useEffect} from "react";
import content from "../assets/content/about.json";
import SectionWithImage from "../components/SectionWithImage";
import "../styles/pages/About.css";
// -----------generator functions -------------
function getSectionWithImageData() {
  return content.SectionWithImageData;
}
// function getDepartmentData(){
//   return content.DepartmentData
// }
// ---------------main function ------------
function AboutPage() {
  // const defaultDepartment = 'Design'
  // const [ismobile,setIsMobile] = useState(false);
  // get data from generator functions
  const secImgData = getSectionWithImageData();
  // const departmentData = getDepartmentData();
  // added by deepak  
  // const [selectedDepartment, setSelectedDepartment] = useState(defaultDepartment);

  // const handleDepartmentClick = (department) => {
  //   if(ismobile){
  //     setSelectedDepartment(department===selectedDepartment?"":department)
  //   }else{
  //     setSelectedDepartment(department);
  //   }
  // };
  // const filteredMembers = departmentData.find((current) => current.department === selectedDepartment);
  // end deepak
  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(max-width: 47em)");
  //   setIsMobile(mediaQuery.matches);
  //   setSelectedDepartment(mediaQuery.matches?"":defaultDepartment);

  //   const handleMediaQueryChange = (event) => {
  //     setSelectedDepartment(event.matches?"":defaultDepartment);
  //     setIsMobile(event.matches);
  //   };

  //   mediaQuery.addEventListener("change", handleMediaQueryChange);

  //   return () => {
  //     mediaQuery.removeEventListener("change", handleMediaQueryChange);
  //   };
  // }, []);
  return (
    <main className="about-page home-div ">
      <section className="service-section | flex flex-col gap-200 pb-800">
        {secImgData.map((element, index) => (
          <SectionWithImage
            key={index}
            imageSrc={element.imageSrc}
            imageAlt={element.imageAlt}
            heading={element.heading}
            paragraph={element.paragraph}
            buttons={element.buttons}
            imageOnLeft={element.imageOnLeft}
            imageOnMobileTop={true}
          />
        ))}
      </section>
      {/* <section>
        <h1>meet out team</h1>
        {!ismobile?(
          <>
          <div className='department-container | w-80 margin-auto' >
            <div className='department-panel' >
                <ul className='department-list' >
                    {departmentData.map((department, index) => (
                        <li key={index} onClick={() => handleDepartmentClick(department.department)}
                            className={`department-item ${department.department === selectedDepartment ? 'active-department':''} ${selectedDepartment === (departmentData[index+1]?departmentData[index+1].department:"") ? "previous-department":''} ${selectedDepartment === (departmentData[index-1]?departmentData[index-1].department:"") ? "next-department":''}`}
                        >{department.department}</li>
                    ))}
                </ul>
                <div class="dropdown-button"></div>
            </div>
            <div className='member' >
                {selectedDepartment && filteredMembers && (
                    <div className='flex flex-wrap justify-content-between gap-200 px-2 pt-1-5'>
                        {filteredMembers.members.map((departmentData, index) => (
                            <div className='member-details' key={index}
                            >
                              <div className="member-image">
                                <img className='w-100 h-100 object-cover' src={departmentData.image} alt={departmentData.name} />
                              </div>
                                <span className='member-name' >{departmentData.name}</span>
                                <small className='member-role' >{departmentData.role}</small>
                            </div>

                        ))}
                    </div>
                )}
            </div>
        </div>
          </>
        ):(
          <>
          <div className='department-container | w-80 margin-auto' >
            <div className='department-panel' >
                <ul className='department-list' >
                    {departmentData.map((department, index) => (
                        <li key={index} onClick={() => handleDepartmentClick(department.department)} className={`department-item department-item-mobile ${department.department === selectedDepartment? "active-department-mobile":""}`}>
                              <div className="department-name-mobile | flex justify-content-between">
                                <span className="d-name">{department.department}</span>
                                {department.department === selectedDepartment?(
                                  <>
                                  <span class="d-icon material-symbols-outlined">expand_less</span>
                                    </>
                                ):(
                                  <>
                                <span className="d-icon material-symbols-outlined ">stat_minus_1</span>
                                  </>
                                )}
                              </div>
                              <div className="department-members-mobile | flex flex-row flex-wrap ">
                              {department.members.map((currentMember, index) => (
                                <div className='member-card' key={index}
                                >   
                                  <div className="member-image">
                                    <img className='w-100 h-100 object-cover' src={currentMember.image} alt={currentMember.name} />
                                  </div>
                                    <span className='member-name' >{currentMember.name}</span>
                                    <small className='member-role' >{currentMember.role}</small>
                                </div>

                            ))}
                              </div>
                        </li>
                    ))}
                </ul>
            </div>
           
        </div>
          </>
        )}
      
      </section>
      <section>
        <div className="kumar-div bg-accent-100  flex flex-col w-100 justify-content-center align-center gap-300">
          <h2 className="text-center">Join@MYEKIGAI</h2>
          <MyButton text="Work With Us" fontWeight="500" fontSize= "1.3rem" padding=".5rem 4rem" href="/career"/>
        </div>
      </section> */}
    </main>
  );
}

export default AboutPage;
