import { Link } from "react-router-dom";
import "../styles/components/notifyContainer.css";
export default function NotifyContainer({onOpenModal,href,text,btnText,boxColor}){
    return(
      <section className=" | w-100 flex justify-content-center px-1 ">
      <div id= "notification-container" className={`notify-box-${boxColor} | w-100 max-w flex p-2`}>
        <div className="small-heading w-50 flex justify-content-center align-center ">
          <p>{text}</p>
        </div>
        <div id = "notification-btn" className="w-50 flex flex-col align-end justify-content-center">
            {
                onOpenModal ?
                    <button onClick={() => onOpenModal("Get in Touch")} className="desktop-download-btn btn ">{btnText}</button>
                    :
                    <Link to={href} className="desktop-download-btn btn ">{btnText}</Link>

            }
        </div>
      </div>
    </section>
    );
  }