import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "../styles/components/Partner.css"
import Marquee from "react-fast-marquee";
import content from "../assets/content/components/partners.json";


const PartnerCarousel = ({partnersList=content.PartnersData}) => {
  const renderImage = (image) => {
    if (image != null) {
      return <LazyLoadImage className='partner-img' effect="blur" placeholderSrc='shimmer.gif' src={image} alt="loading" />;
    } else {
      return  <div className="partner-placeholder-image " />;
    }
  };
  return (
    <div className="flex flex-col text-center justify-content-center mt-500" >
        <Marquee speed={200} gradient gradientWidth={50} pauseOnClick={true}>
            {partnersList.map((partner,index)=>{
                return(
                  <React.Fragment key={index}>
                    <a href={partner.link} >{renderImage(partner.image)}</a>
                  </React.Fragment>
                )
              })}
      </Marquee>
  </div>
  );
};

export default PartnerCarousel;
