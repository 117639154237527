import { useState ,useRef,useEffect} from "react";
import "../styles/pages/Stories.css"
import content from "../assets/content/stories.json"
// for archivements 
import { Gallery} from "react-grid-gallery";
// for team outing  
import ImageGallery from "react-image-gallery"
import { Modal } from 'react-responsive-modal';
import NotifyContainer from "../components/NotifyContainer";

import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function StoriesPage(){
    const modalTypes={AWARDS:"awards",OUTING_IMAGES:"outingImages"};
    const [openModal, setOpenModal] = useState(false);
    const [outingData,setOutingData] = useState(content.outingImages[0]);
    const [modalType,setModalType] = useState(modalTypes.AWARDS);  
    const [awardData,setAwardData] = useState(content.awards[0]);  
    const [isPrevVisible, setIsPrevVisible] = useState(false);
    const [isNextVisible, setIsNextVisible] = useState(true);
    const scrollContainerRef = useRef(null);
    const openOutingModal = (imgIndex) => {
        setModalType(modalTypes.OUTING_IMAGES)
        setOutingData(content.outingImages[imgIndex]);
        setOpenModal(true);
    };
    const openProfOutingModal = (imgIndex) => {
        setModalType(modalTypes.OUTING_IMAGES)
        setOutingData(content.profectionalOuting[imgIndex]);
        setOpenModal(true);
    };
    const openAwardModal =(awardIndex) =>{
            setModalType(modalTypes.AWARDS);
            setAwardData(content.awards[awardIndex]);
            setOpenModal(true);

    }

    const onCloseModal = () => setOpenModal(false);
    const updateButtonVisibility = () => {
        const container = scrollContainerRef.current;
        if (container) {
          const { scrollLeft, scrollWidth, clientWidth } = container;
          setIsPrevVisible(scrollLeft > 0); // Show "Prev" button if not at the start
          setIsNextVisible((scrollLeft + clientWidth+10) < scrollWidth); // Show "Next" button if not at the end
        }
      };
      const scroll = (direction) => {
        const container = scrollContainerRef.current;
        if (container) {
          const scrollAmount = 400; // Adjust for desired scroll distance
          if (direction === "next") {
            container.scrollLeft += scrollAmount;
          } else if (direction === "prev") {
            container.scrollLeft -= scrollAmount;
          }
        }
      };
      useEffect(() => {
        updateButtonVisibility(); // Initial check
        const container = scrollContainerRef.current;
    
        if (container) {
          // Update visibility on scroll
          container.addEventListener("scroll", updateButtonVisibility);
          return () => container.removeEventListener("scroll", updateButtonVisibility);
        }
      }, []);
    return (
        <main className="storiesPage | home-div ">
             <Modal open={openModal} onClose={onCloseModal} center classNames={{ modal: "br-1" }}>
                {
                    (modalType === modalTypes.AWARDS)?
                            <section className="mt-300">
                                <div className="award-img | w-100  text-left ">
                                            <LazyLoadImage placeholderSrc="shimmer.gif" effect="blur" src={awardData.src} alt="Cart" max-width="100%" height="100%" />
                                </div>
                                <div className="award-card-body | mt-100 ">
                                    <div className=" regular-heading font-regular text-left">
                                        <h1>{awardData.heading}</h1>
                                    </div>
                                </div>
                                <div className="w-100 mt-100">
                                    {
                                        awardData.images &&
                                            <div className="gallery-wrapper">
                                            <ImageGallery showNav={false} items={awardData.images} showThumbnails={false} autoPlay={true} showPlayButton={false} showFullscreenButton={false} slideInterval={3000}/>
                                            </div>  
                                    }
                                </div>
                                <div className="mt-100 | text-graniteGray" >
                                    <p>
                                    {awardData.desc}
                                    </p>
                                </div>

                            </section>
                        :
                        <div className="markdown-wrapper p-1 mt-100">
                            <img src={outingData.src} alt={outingData.src} />
                            {
                                outingData.desc &&
                                <div className="mt-100 | text-graniteGray" >
                                    <p>
                                    {outingData.desc}
                                    </p>
                                </div>
                            }
                        </div>  
                }
                        
                  </Modal>
            
            <section className=" h-70vh p-1 flex justify-content-center mt-100"   style={{boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",}}>
                <div className="max-w flex justify-content-center flex-col p-1">
                    <div className="  primary-heading font-bold text-center w-100">
                        <h1 >Driving India Forward: Innovative, Accessible<span className="text-primary">, and Eco-Friendly Electric Solutions </span></h1>
                    </div>
                    <div className="text-center text-dark-grey text-medium font-regular mt-100">
                        <p>At MYeKIGAI, we are revolutionizing transportation with innovative EV rentals, advanced charging, and seamless battery swapping solutions. Our mission is to make sustainable mobility accessible to everyone, breaking barriers of geography and affordability. By driving the transition to electric vehicles, we are paving the way for a cleaner and greener future for India.</p>
                    </div>
                </div>
            </section>
            <section className=" w-100 text-center flex flex-col align-center " >
                <div className="w-100 flex flex-col font-bold text-center relative overflow-hidden">
                    <div className="secondary-heading w-100 text-center">
                        <h1 >Awards & Recognitions</h1> 
                    </div>
                    {
                        isPrevVisible &&
                        <button className="what-others-say-carousel-arrow prev" onClick={()=>(scroll("prev"))}> <span className="material-symbols-outlined">chevron_left</span>
                        </button>
                    }
                        <div  className="horizontal-scrolable-div mt-300 " ref={scrollContainerRef} >
                            {
                                content.awards.map((award,index)=>{
                                    return (
                                        <div onClick={()=>(openAwardModal(index))} className="cursor-pointer awards-cards text-left p-2" key={index}>
                                        <div className="award-img | w-100 h-20 text-left ">
                                            <LazyLoadImage placeholderSrc="shimmer.gif" effect="blur" src={award.src} alt="Cart" max-width="100%" height="100%" />
                                        </div>
                                        <div className="award-card-body">
                                            <div className=" regular-heading font-regular">
                                                <h1  className="line-clamp" style={{"--line-clamp":1}} >{award.heading}</h1>
                                            </div>
                                            <div className="award-para | text-graniteGray " >
                                                <p className="line-clamp" style={{"--line-clamp":6}}>
                                                {award.desc}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                })
                            }
                          
                          
                        </div>
                    {
                        isNextVisible &&
                        <button className="what-others-say-carousel-arrow next"  onClick={()=>(scroll("next"))}> <span className="material-symbols-outlined">chevron_right</span>
                        </button>
                    }
                </div>
            </section>
            <section className=" w-100 p-1">
                <div className="max-w  flex flex-col justify-content-center margin-auto text-center  p-2">
                    <div className="secondary-heading font-bold text-center w-100">
                        <h1>Team Outing & Meetups</h1>
                    </div>
                    <div className="w-100 mt-200 flex gap-200">
                        <div className="w-100">
                            <Gallery images={content.profectionalOuting} onClick={openProfOutingModal} enableImageSelection={false}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className=" w-100 p-1">
                <div className="max-w  flex flex-col justify-content-center margin-auto text-center  p-2">
                    <div className="secondary-heading font-bold text-center w-100">
                        <h1>Birthdays & Celebrations</h1>
                    </div>
                    <div className="w-100 mt-200 flex gap-200">
                        <div className="w-100">
                            <Gallery images={content.outingImages} onClick={openOutingModal} enableImageSelection={false}/>
                        </div>
                    </div>
                </div>
            </section>
              <section className="w-100 flex justify-content-center px-1 my-2">
                   <NotifyContainer boxColor="green" href="/career" text="Empower your journey and build a thriving future with MYeKIGAI." btnText="Join Us Now"/>
                  </section>

        </main>
    )
}