import content from "../assets/content/services.json"
import SectionWithImage from "../components/SectionWithImage";
import "../styles/pages/Services.css"
// -----------generator functions -------------
function getServicesData(){
  return content.ServicesData
}

// ---------------main function ------------
function ServicesPage() {
  // getting data from generators 
  const ServicesData = getServicesData()
  return (
    <main className="service-page | home-div">
      <section className="service-section | flex flex-col gap-200">
          {ServicesData.services.map((service,index) => (
             <SectionWithImage
              key = {index}
              imageSrc={service.imageSrc}
              imageAlt={service.imageAlt}
              heading={service.heading}
              paragraph={service.paragraph}
              buttons={service.buttons}
              imageOnLeft={service.imageOnLeft}
              imageOnMobileTop={true}
           />
          ))}
      </section>
     
    </main>
  );
}

export default ServicesPage;