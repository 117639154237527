import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "../styles/components/SectionWithImage.css"
import {Link} from "react-router-dom"
function RenderContent({heading,subHeading, paragraph, buttons ,triggerPopUp,buttonClasses}){
  const btn_classes = buttonClasses ? `${buttonClasses} section-btn btn` : "section-btn btn"
    return (
                <div className="content">
                    <h1 className='secondary-heading'>{heading}</h1>
                    {subHeading?(
                      <h2>{subHeading}</h2>
                    ):(
                      <></>
                    )
                    }
                    <p>{paragraph}</p>
                    <div className="section-btn-div">
                    {buttons.map((button, index) => (
                        triggerPopUp?(
                          <Link className={btn_classes} key={index} onClick={()=>(triggerPopUp(index))}>
                          {button.text}
                          </Link>
                        ):(

                          <Link className={btn_classes} key={index} to={button.href}>
                          {button.text}
                          </Link>
                          )
                    ))}
                    </div>
                </div>
            );
        }

function RenderImage({imageSrc,imageAlt,imageContainerCustomStyles}){
    if (imageSrc) {
      return (<>
        <div className="section-image"> <span style={imageContainerCustomStyles} className='img-span'> 
          <LazyLoadImage    effect="blur"  placeholderSrc="shimmer.gif" src={imageSrc}  alt={imageAlt} />
        </span>
        </div>
         </>);
    } else {
      return <div className="section-image "> <div className="placeholder-image" /></div>;
    }
  };
// bydefault imageOnMobileTop is false so you don't have to specify that
function SectionWithImage({ imageSrc, imageAlt, heading,subHeading, paragraph, buttons,buttonClasses, imageOnLeft ,imageOnMobileTop,triggerPopUp,imageContainerCustomStyles,classNames=""}){
  return (
    <div className={`${classNames} section-with-image ${imageOnLeft ? 'image-on-left' : 'image-on-right'} ${imageOnMobileTop ? 'image-on-mobile-top' : 'image-on-mobile-bottom'}`}>
      <div className="section-content">
        {imageOnLeft ? (
          <>
            <RenderImage  imageAlt={imageAlt} imageSrc={imageSrc} imageContainerCustomStyles={imageContainerCustomStyles}/>
            {/* rendering content  */}
            {typeof(paragraph) === 'object'?(
              <RenderContent 
              
              heading={heading}
              subHeading={subHeading}
              paragraph={ <>
                {Object.values(paragraph).map((currentPara,index)=>{
                  return (
                    <span key={index}>
                        {currentPara}
                      {Object.keys(paragraph).length !== (index+1)&&(
                        <>
                        <br /> <br />
                        </>
                      )
                      }
                    </span>
                  )
                })}
              </>}
              buttons={buttons}
              imageOnLeft={imageOnLeft} 
              triggerPopUp={triggerPopUp}
              buttonClasses={buttonClasses}
              />
             
            ):(
              <RenderContent  imageAlt={imageAlt}
              heading={heading}
              subHeading={subHeading}
              paragraph={paragraph}
              buttons={buttons}
              imageOnLeft={imageOnLeft}
              triggerPopUp={triggerPopUp}
              buttonClasses={buttonClasses}
              />
            )}
            
          </>
        ) : (
          <>
            {typeof(paragraph) === 'object'?(
              <RenderContent 
              heading={heading}
              subHeading={subHeading}
              paragraph={ <>
                {paragraph.beforeBreakPoint}
                <br /> <br />
                {paragraph.afterBreakPoint}
              </>}
              buttons={buttons}
              imageOnLeft={imageOnLeft} 
              triggerPopUp={triggerPopUp}
              buttonClasses={buttonClasses}
              />
              
             
            ):(
              <RenderContent  imageAlt={imageAlt}
              heading={heading}
              subHeading={subHeading}
              paragraph={paragraph}
              buttons={buttons}
              imageOnLeft={imageOnLeft}
              triggerPopUp={triggerPopUp}
              buttonClasses={buttonClasses}
              />
            )}
            {/* rendering image after content  */}
            <RenderImage imageAlt={imageAlt} imageSrc={imageSrc} imageContainerCustomStyles={imageContainerCustomStyles}/>
            
          </>
        )}
      </div>
    </div>
  );
};

export default SectionWithImage;
