import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "../styles/components/Header.css";
import closeIcon from "../assets/otherIcons/close.svg";
import NavDropDown from "./NavDropDown";
import content from "../assets/content/components/header.json"
// utility componenets 
function MobileNav({popUpOpen,isMenuOpen, handleMenuToggle,isTab,isMobile}){
  const location = useLocation();
  return(
    <>
    {/* download btn  */}
    {/* <Link className="btn mobile-download-btn" onClick={popUpOpen}>Download App</Link> */}
    {/* hamburger btn  */}
    <button className="hamburger-icon" onClick={handleMenuToggle}>
      <span className={`line ${isMenuOpen ? "line-open" : ""}`}></span>
      <span className={`line ${isMenuOpen ? "line-open" : ""}`}></span>
      <span className={`line ${isMenuOpen ? "line-open" : ""}`}></span>
    </button>
    {/* initail hidden navigation offcanvas for mobile devices */}
    <nav className={`primary-nav-mobile | ${isMenuOpen ? "open" : ""} z-index-1`}>
      <div className="flex flex-col h-100 w-100  align-self-end">
        <div className="w-100 text-right ">
        <button
          className="hamburger-icon mobile close"
          onClick={handleMenuToggle}
        >
          {/* <span
            className={`line ${isMenuOpen ? "line-open" : ""}`}
          ></span>
          <span
            className={`line ${isMenuOpen ? "line-open" : ""}`}
          ></span>
          <span
            className={`line ${isMenuOpen ? "line-open" : ""}`}
          ></span> */}
          <LazyLoadImage effect="blur" src={closeIcon} alt={closeIcon} />
        </button>
        </div>
        <ul
          aria-label="Primary"
          className="sm-mobile-devices mobile-nav-list | flex justify-content-center  flex-col w-100"
        >
          <li>
            <Link
              to="/"
              className={location.pathname === "/" ? " active-mobile" : ""}
              onClick={handleMenuToggle}
            >
              Home
            </Link>
          </li>
          <li>
           <NavDropDown 
            menuName={content.solutionsServicesDropDown.name}
            subMenus={content.solutionsServicesDropDown.subMenus}
            handleMenuToggle={handleMenuToggle}
            isTab={isTab}
            isMobile={isMobile}
           />
          </li>
          <li>
           <NavDropDown 
            menuName={content.aboutServicesDropDown.name}
            subMenus={content.aboutServicesDropDown.subMenus}
            handleMenuToggle={handleMenuToggle}
            isTab={isTab}
            isMobile={isMobile}
           />
          </li>
          <li>
            <Link
              to="/partner"
              className={
                location.pathname === "/partner" ? " active-mobile" : ""
              }
              onClick={handleMenuToggle}
            >
              Partner With Us
            </Link>
          </li>
          <li>
            <Link
              to="/career"
              className={
                location.pathname === "/career" ? "active-mobile" : ""
              }
              onClick={handleMenuToggle}
            >
              Careers
            </Link>
          </li>
          {/* <li>
            <Link
              to="/blogs"
              className={location.pathname === "/blogs" ? "active active-mobile" : ""}
              onClick={handleMenuToggle}
            >
              Blog
            </Link>
          </li> */}
          <li>
            <Link
              to="/contact"
              className={
                location.pathname === "/contact" ? "active-mobile" : ""
              }
              onClick={handleMenuToggle}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </>
  );
}
function TabNav({popUpOpen,isMenuOpen, handleMenuToggle,isTab,isMobile}){
  const location = useLocation();
  return (
    <>
    {/* download btn  */}
    {/* <Link className="btn mobile-download-btn" onClick={popUpOpen}>Download App</Link> */}
    {/* hamburger btn  */}
    <button className="hamburger-icon" onClick={handleMenuToggle}>
      <span className={`line ${isMenuOpen ? "line-open" : ""}`}></span>
      <span className={`line ${isMenuOpen ? "line-open" : ""}`}></span>
      <span className={`line ${isMenuOpen ? "line-open" : ""}`}></span>
    </button>
    {/* initail hidden navigation offcanvas for mobile devices */}
    <nav className={`primary-nav-tab tab | ${isMenuOpen ? "open" : ""} z-index-1`}>
      <div className="flex flex-col h-100 w-100  align-self-end">
        <div className="w-100 text-right ">
        <button
          className="hamburger-icon close"
          onClick={handleMenuToggle}
        >
          <LazyLoadImage effect="blur" src={closeIcon} alt={closeIcon} />
        </button>
        </div>
        <ul
          aria-label="Primary"
          className="mobile-nav-list tab | flex justify-content-center  flex-col w-100"
        >
          <li>
            <Link
              to="/"
              className={location.pathname === "/" ? " active-mobile" : ""}
              onClick={handleMenuToggle}
            >
              Home
            </Link>
          </li>
          <li>
           <NavDropDown 
            menuName={content.solutionsServicesDropDown.name}
            subMenus={content.solutionsServicesDropDown.subMenus}
            handleMenuToggle={handleMenuToggle}
            isTab={isTab}
            isMobile={isMobile}
           />
          </li>
          <li>
           <NavDropDown 
            menuName={content.aboutServicesDropDown.name}
            subMenus={content.aboutServicesDropDown.subMenus}
            handleMenuToggle={handleMenuToggle}
            isTab={isTab}
            isMobile={isMobile}
           />
          </li>
          <li>
            <Link
              to="/partner"
              className={
                location.pathname === "/partner" ? " active-mobile" : ""
              }
              onClick={handleMenuToggle}
            >
              Partner With Us
            </Link>
          </li>
          <li>
            <Link
              to="/career"
              className={
                location.pathname === "/career" ? "active-mobile" : ""
              }
              onClick={handleMenuToggle}
            >
              Careers
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={
                location.pathname === "/contact" ? "active-mobile" : ""
              }
              onClick={handleMenuToggle}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </>
  );
}
function DesktopNav({popUpOpen,isTab,isMobile}){
  const location = useLocation();
  return (
    <>
    <div className="flex gap-200">
      <nav className="primary-nav | flex align-center z-index-1">
        <ul
          aria-label="Primary"
          className="nav-list | gap-200 flex text-center"
        >
          <li>
            <Link
              to="/"
              className={location.pathname === "/" ? "active" : ""}
            >
              Home
            </Link>
            <div className="border-line"></div>
          </li>
          <li>
           <NavDropDown 
            menuName={content.solutionsServicesDropDown.name}
            subMenus={content.solutionsServicesDropDown.subMenus}
            borderEnabled={true}
            isTab={isTab}
            isMobile={isMobile}
           />
          </li>
           <li>
           <NavDropDown 
            menuName={content.aboutServicesDropDown.name}
            subMenus={content.aboutServicesDropDown.subMenus}
            borderEnabled={true}
            isTab={isTab}
            isMobile={isMobile}
           />
          </li>
          <li>
            <Link
              to="/partner"
              className={location.pathname === "/partner" ? "active" : ""}
            >
              Partner With Us
            </Link>
            <div className="border-line"></div>
          </li>
          <li>
            <Link
              to="/career"
              className={location.pathname === "/career" ? "active" : ""}
            >
              Careers
            </Link>
            <div className="border-line"></div>
          </li>
          {/* <li>
            <Link
              to="/blogs"
              className={location.pathname === "/blogs" ? "active" : ""}
            >
              Blog
            </Link>
          </li> */}
          <li>
            <Link
              to="/contact"
              className={location.pathname === "/contact" ? "active" : ""}
            >
              Contact Us
            </Link>
            <div className="border-line"></div>
          </li>
        </ul>
      </nav>
      {/* <Link className="desktop-download-btn btn"  onClick={popUpOpen}>Download App</Link> */}
      </div>
    </>
  );
}
function NavigationMenu({isMobile,isTab, popUpOpen,isMenuOpen,handleMenuToggle}){

    if(isMobile){
      return <MobileNav  isMobile={isMobile} isTab={isTab} popUpOpen={popUpOpen} isMenuOpen={isMenuOpen} handleMenuToggle={handleMenuToggle}/>
    }else if (isTab){
      return <TabNav  isMobile={isMobile} isTab={isTab} popUpOpen={popUpOpen} isMenuOpen={isMenuOpen} handleMenuToggle={handleMenuToggle}/>
    }
    else{
      return <DesktopNav  isMobile={isMobile} isTab={isTab} popUpOpen={popUpOpen}/>
    }
}
function Header({popUpOpen}) {
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    
  };
  useEffect(() => {
    // handling background when nav menu is open 
    const closeMenu = ()=>{
      setIsMenuOpen(false);
      document.querySelector("main").removeEventListener("click",closeMenu);
    }
    if (isMenuOpen) {
      document.querySelector("main").classList.add("opacity-half")
      document.querySelector("main").addEventListener("click",closeMenu)
      document.body.classList.add('hide-overflow');
    } else {
      document.querySelector("main").classList.remove("opacity-half")
      document.body.classList.remove('hide-overflow');
    }
    // handeling nav-menu for mobile tab or desptop devices 
    const mediaQueryForTab = window.matchMedia("(min-width: 451px) and (max-width: 768px)");
    const mediaQueryForMobile = window.matchMedia("(max-width: 500px)");
    setIsTab(mediaQueryForTab.matches);
    setIsMobile(mediaQueryForMobile.matches);

    const handleMediaQueryChangeForTab = (event) => {
      setIsTab(event.matches);
    };
    const handleMediaQueryChangeForMobile = (event) => {
      setIsMobile(event.matches);
    };

    mediaQueryForTab.addEventListener("change", handleMediaQueryChangeForTab);
    mediaQueryForMobile.addEventListener("change", handleMediaQueryChangeForMobile);

    return () => {
      mediaQueryForTab.removeEventListener("change", handleMediaQueryChangeForTab);
      mediaQueryForMobile.removeEventListener("change", handleMediaQueryChangeForMobile);
    };
  }, [isMenuOpen,setIsMenuOpen]);

  return (
    <>
    <header>
      <div className="w-100  flex justify-content-between align-center">
        <Link
          className="flex gap-50 h-100 align-center no-decoration text-black | logo-link"
          to="/"
        >
          <LazyLoadImage effect="blur" className="logo-svg" src="/logo.png" alt="Loading Icon" />
          <span className="logo-name | flex justify-content-center  flex-col h-100">
            <h1 className="mt-50">MYeKIGAI</h1>
          </span>
        </Link>
        <NavigationMenu isMobile={isMobile} isTab={isTab} popUpOpen={popUpOpen} isMenuOpen={isMenuOpen} handleMenuToggle={handleMenuToggle}/>
      </div>
    </header>
    </>
  );
}

export default Header;
