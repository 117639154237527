// import { useState ,useEffect} from "react";
import { useParams } from "react-router-dom";
// import SectionWithImage from "../components/SectionWithImage";
// import { WhatOtherSays, WhatOtherSaysCard } from "../components/WhatOtherSays";
import MyButton from "../components/MyButton";
// import content from "../assets/content/career.json";
import "../styles/pages/Career.css";
import "../styles/pages/CareerDescription.css";
import { useState } from "react";
// third party libs 
import {RingLoader} from "react-spinners";
// import env from '../assets/credentials/env'
// external libs imports
// import { Store } from 'react-notifications-component';
// generator function
// function getSectionWithImageFirstData() {
//   return content.SectionWithImageFirstData;
// }
// function getEmployeesSaysDate() {
//   return content.EmployeeSaysData;
// }
function CareerPage() {
  // states 
  const [loading,setLoading] = useState(true);
  // // states
  // const [jobs, setJobs] = useState(null);
  // // generating static data from ../assets/content/ folder
  // const FirstSecImgData = getSectionWithImageFirstData();
  // const EmployeeSays = getEmployeesSaysDate();
  // useEffect(() => {
  //   fetch(`${env.API_END_POINT}/job-post`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: env.API_ACCESS_TOKEN,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log(response.json());
  //         // setJobs(response.json());
  //       } else {
  //         Store.addNotification({
  //           title: "Unsuccessful ",
  //           message: "Try refreshing the page",
  //           type: "danger",
  //           insert: "bottom",
  //           container: "bottom-right",
  //           animationIn: ["animate__animated", "animate__fadeInRight"],
  //           animationOut: ["animate__animated", "animate__fadeOutRight"],
  //           dismiss: {
  //             duration: 3000,
  //             timingFunction: "ease-out",
  //             showIcon: true,
  //             click: false,
  //           },
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       Store.addNotification({
  //         title: "Unsuccessfull ",
  //         message: "Unable to connect with server",
  //         type: "danger",
  //         insert: "bottom",
  //         container: "bottom-right",
  //         animationIn: ["animate__animated", "animate__fadeInRight"],
  //         animationOut: ["animate__animated", "animate__fadeOutRight"],
  //         dismiss: {
  //           duration: 2000,
  //           timingFunction: "ease-out",
  //           showIcon: true,
  //           click: false,
  //         },
  //       });
  //     });
  // }, []);

  // const id = 1
  return (
    // <main className="home-div">
    //   <section>
    //     <SectionWithImage
    //       imageSrc={FirstSecImgData.imageSrc}
    //       imageAlt={FirstSecImgData.imageAlt}
    //       heading={FirstSecImgData.heading}
    //       paragraph={FirstSecImgData.paragraph}
    //       buttons={FirstSecImgData.buttons}
    //       imageOnLeft={FirstSecImgData.imageOnLeft}
    //     />
    //   </section>
    //   <section>
    //     <h1>Why Work With Us</h1>
    //     <div className="flex flex-col align-center gap-100">
    //       <p className="text-center">
    //         Join MYeKIGAI to experience teamwork, corporate culture, and achieve
    //         your vision.
    //       </p>
    //       <div class="why-with-us-card-container w-90">
    //         <div class="why-with-us-card-child">
    //           <div className="with-us-title">Innovation</div>
    //           <p className="why-us-description">
    //             Innovation is at the heart of our start-up culture at MYeKIGAI.
    //           </p>
    //         </div>
    //         <div class="why-with-us-card-child">
    //           <div className="with-us-title">Experience</div>
    //           <p className="why-us-description">
    //             Gain experience in strategic planning, marketing, branding and
    //             much more and take your skills to the next level with us.
    //           </p>
    //         </div>
    //         <div class="why-with-us-card-child">
    //           <div className="with-us-title">Recognition</div>
    //           <p className="why-us-description">
    //             We prioritize your success and strive to ensure that you get the
    //             most value out of our partnership
    //           </p>
    //         </div>
    //         <div class="why-with-us-card-child">
    //           <div className="with-us-title">Teamwork</div>
    //           <p className="why-us-description">
    //             Work collaboratively on every task and learn many new things
    //             with us.
    //           </p>
    //         </div>
    //         <div class="why-with-us-card-child">
    //           <div className="with-us-title">Workplace Culture</div>
    //           <p className="why-us-description">
    //             Get valuable training in the corporate work environment.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <section>
    //     <h1>What Our Employees Say's</h1>
    //     <WhatOtherSays controlType="arrow-on-sides">
    //       {EmployeeSays.map((employee, index) => (
    //         <WhatOtherSaysCard
    //           key={index}
    //           imageSrc={employee.imageSrc}
    //           imageAlt={employee.imageAlt}
    //           consumerName={employee.employeeName}
    //           summary={employee.summary}
    //         />
    //       ))}
    //     </WhatOtherSays>
    //   </section>
    //   <section>
    //     <h1>Career Opportunities</h1>
    //     <div className="flex flex-row butter">
    //       <div className="filterbox">
    //         <div className="filterClear">
    //           <h1 className="filter">Filter By</h1>
    //           <h2 id="clear">Clear all</h2>
    //         </div>
    //         <h3 style={{ fontWeight: "900", fontSize: "1.25em" }}>Level</h3>
    //         <div className="bread">
    //           <div className="flex flex-col bread-container1">
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job"
    //                 name="job"
    //                 value="Internship"
    //                 className="checkbox"
    //               />
    //               <label for="job">Internship</label>
    //             </div>
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job2"
    //                 name="job2"
    //                 value="Entry Level"
    //                 className="checkbox"
    //               />
    //               <label for="job2">Entry Level</label>
    //             </div>
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job3"
    //                 name="job3"
    //                 value="Mid-Level"
    //                 className="checkbox"
    //               />
    //               <label for="job3">Mid-Level</label>
    //             </div>
    //           </div>
    //           <h3 style={{ fontWeight: "900", fontSize: "1.25em" }}>
    //             Department
    //           </h3>
    //           <div className="flex flex-col bread-container2">
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job4"
    //                 name="job4"
    //                 value="Design"
    //                 className="checkbox"
    //               />
    //               <label for="job4">Design</label>
    //             </div>
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job5"
    //                 name="job5"
    //                 value="Technical"
    //                 className="checkbox"
    //               />
    //               <label for="job5">Technical</label>
    //             </div>
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job6"
    //                 name="job6"
    //                 value="Marketing"
    //                 className="checkbox"
    //               />
    //               <label for="job6">Marketing</label>
    //             </div>
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job7"
    //                 name="job7"
    //                 className="checkbox"
    //                 value="Business"
    //               />
    //               <label for="job7">Business</label>
    //             </div>
    //             <div>
    //               <input
    //                 type="checkbox"
    //                 id="job8"
    //                 name="job8"
    //                 value="Human Resource"
    //                 className="checkbox"
    //               />
    //               <label for="job8">Human Resource</label>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="jam">
    //         <div className="searchbarContainer">
    //           <input
    //             type="text"
    //             name="search"
    //             placeholder="Search by Jobs"
    //             className="searchbar"
    //           />
    //           <div className="searchIconBox">
    //             {/* <img id="searchIcon" src={searchIcon} alt="searchIcon" /> */}
    //             <span id="searchIcon" class="material-symbols-outlined">
    //               search
    //             </span>
    //           </div>
    //         </div>
    //         <div className="flex cardbox">
    //           <div className="cardcontainer">
    //             <div className="card">
    //               <h1>Ui/Ux Experience</h1>
    //               <div className="experienceBox">
    //                 <p id="experienceLevel">Experience Level :</p>
    //                 <p id="experience">1-2 years</p>
    //               </div>
    //               <p>
    //                 <button>Apply</button>
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="morejobs">
    //           <button id="moreJobs">More Jobs</button>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </main>
    <main>
           <RingLoader
           color={"#076cdf"}
           loading={loading}
           cssOverride={{"margin":"2rem auto","display":"block"}}
           size={150}
           aria-label="Loading Spinner"
           data-testid="loader"
         />
         <iframe
         title="career-page"
         id="career-frame"
         src="https://app.pyjamahr.com/careers?company=MYeKIGAI%20PROFOUND%20Pvt.%20Ltd.&company_uuid=0B2841B6DE&isHeaderVisible=true&is_careers_page=true"
         frameborder="0"scrolling="yes" seamless="seamless" 
         style={{ display: 'block', width: '100%', height:"100vh" ,border:"none" }}
         onLoad={()=>{setLoading(false)}}
       ></iframe>
    </main>
  );
}

function CareerDescription() {
  const { id } = useParams();
  console.log(id);
  return (
    <>
      <main>
        <section className="job-meta-section | flex justify-content-between">
          <div>
            <h1 className="job-title">UI/UX Design</h1>
            <div className="| flex w-100 gap-300 px-50 text-primary-accent font-medium-bold pt-50">
              <small>Department</small>
              <small>Loaction</small>
              <small>Level</small>
            </div>
          </div>
          <div>
            <MyButton text="Apply" />
          </div>
        </section>
        <section></section>
        <section></section>
        <section></section>
      </main>
    </>
  );
}
export { CareerPage, CareerDescription };
