import { Tilt } from 'react-tilt'

import "../styles/components/beneficiaries.css"

function BeneCard({beneHeading,beneDes,beneImgSrc}){
  return(
    <Tilt
    options={{
      max: 45,
      scale: 1,
      speed: 450,
    }}
    className='bene-cards p-2 br-1'
  >
  <div className="bene-card-img | flex w-auto justify-content-center ">
    <img className="w-auto h-100" src={beneImgSrc}/>
  </div>
  <div>
    <div className="text-center text-regular-forced text-white font-bold mt-100">
      <h3>{beneHeading}</h3>
    </div>
    <div id="bene-des" className="text-center text-regular font-regular text-grey mt-50">
      <p>
      {beneDes}
      </p>
    </div>
  </div>
</Tilt>
  )
}
export default function BeneficiariesSection(){
  return(
    <section className="w-100 flex justify-content-center px-1 ">
      <div id= "bene-container" className="container br-3">
        <h1 className="flex justify-content-center text-white text-md text-bold text-center ">MYeKIGAI CMS beneficiaries</h1>
        <div className="bene-card-wrapper pt-2">
        <BeneCard beneHeading="Charge Point Operators" beneDes="Enabling smarter and cost-effective station management." beneImgSrc="/solutionImages/chargers.svg"/>
        <BeneCard beneHeading="OEMs" beneDes="MYeKIGAI CMS boosts OEM efficiency with smart tech, accessible anytime." beneImgSrc="/solutionImages/factory1.svg"/>
        <BeneCard beneHeading="EV fleet operators" beneDes="Scale your EV charging business affordably with our efficient operations." beneImgSrc="/solutionImages/evcar.svg"/>
        <BeneCard beneHeading="Government Organizations" beneDes="Streamline EV infrastructure management and drive sustainability with our comprehensive solutions tailored for public enterprises." beneImgSrc="/solutionImages/gov.svg"/>
        </div>
      </div>
    </section>
  )
}