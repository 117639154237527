import "../styles/pages/Contact.css"
import ContactForm from "../components/Contact"
function ContactUsPage() {
  return (
    <main className="contact-div | home-div pb-800">
      <section className="contact-section">
          {/* <div className="home-div">
            <section className="pb-800">
            <h1 className="text-unset">Find us here</h1>
            <small>Lorem ipsum dolor sit amet consectetur. Malesuada ac sit hendrerit maecenas in. </small>
            </section>
          </div> */}
          <h1 className="py-2">Contact Us</h1>
          <div >
            <ContactForm row_count={3}></ContactForm>
          </div>
      </section>
      <section className="location-section ">
        <div className="address-container home-div">
          <section>
            <h2>Office Address</h2>
            <small>DTU-IIF, Delhi Technological University, Delhi - 110042</small>
          </section>
        </div>
        <div className="location-map">
          {/* <img src="map.png" alt="map.png"/> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3497.9591870999643!2d77.11386727519745!3d28.75063547560044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d01abefc7bcb7%3A0x9b0f12d9d63ef6d5!2sMYeKIGAI%20(Me)!5e0!3m2!1sen!2sin!4v1687141857760!5m2!1sen!2sin" width="600" height="450"  style={{border:0}} title="location-map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </section>
    </main>
  );
}

export default ContactUsPage;