import { useState, useRef } from "react";
import content from "../assets/content/solution.json";
import SectionWithImage from "../components/SectionWithImage";
import ContactForm from "../components/Contact";
import "../styles/pages/Solutions.css";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";  // Assuming useGSAP is the appropriate hook you're using
import ScrollTrigger from "gsap/ScrollTrigger";
import BeneficiariesSection from "../components/BeneficiariesSection"
import PartnerCarousel from "../components/Partner";
import NotifyContainer from "../components/NotifyContainer";
// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

// Generator functions
function getSectionWithImageFirstData() {
  const dataDict = content.SectionWithImageFirstData;
  // modifications in content
  const heading = (
    <>
      {dataDict.headingInBlack}
      <span className="text-primary"> {dataDict.headingInBlue} </span>
    </>
  );
  const data = {
    imageSrc: dataDict.imageSrc,
    imageAlt: dataDict.imageAlt,
    heading: heading,
    paragraph: dataDict.paragraph,
    buttons: dataDict.buttons,
    imageOnLeft: dataDict.imageOnLeft,
  };
  return data;
}

function getSectionWithImageSecondData() {
  return content.SectionWithImageSecondData;
}

function getSectionWithImageThirdData() {
  return content.SectionWithImageThirdData;
}

function getSectionWithImageFourthData() {
  return content.SectionWithImageFourthData;
}

// SolutionPage component
function SolutionPage() {
  const FirstSecImgData = getSectionWithImageFirstData();
  const SecondSecImgData = getSectionWithImageSecondData();
  const ThirdSecImgData = getSectionWithImageThirdData();
  const FourthSecImgData = getSectionWithImageFourthData();

  const [openModal, setOpenModal] = useState(false);
  const [btnText, setBtnText] = useState("Submit");

  const onOpenModal = (btnText = "Submit") => {
    setBtnText(btnText);
    setOpenModal(true);
  };

  const onCloseModal = () => setOpenModal(false);

  // Use ref for scroll-triggered sections
  const secondSectionRef = useRef();
  const thirdSectionRef = useRef();
  const fourthSectionRef = useRef();

  // action (animation) is pinning the current section 
  useGSAP(() => {
    const sections = [secondSectionRef, thirdSectionRef, fourthSectionRef]; // Array of section refs
    sections.forEach((sectionRef, index) => {
      const isLastSection = index === (sections.length - 1);
      if (index <= sections.length - 1) {
        gsap.to(( isLastSection ? sectionRef.current : sections[index + 1].current), {
          scrollTrigger: {
            trigger: sectionRef.current,  
            endTrigger: isLastSection ? sectionRef.current : sections[index + 1].current,
            // (current , viewport) 
            start: "top top",           // Start when the bottom of the current section hits the bottom of the viewport
            // (next , viewport) 
            end: "top top",             // End when the bottom of the next section hits the bottom of the viewport
            scrub: true,
            pin: true,
            pinSpacing: false,                // Avoid extra space after pin
          },
        });
      }
    });
  }, { scope: [secondSectionRef, thirdSectionRef, fourthSectionRef] });
  

  return (
    <main className="solution-page | home-div">
      <Modal open={openModal} onClose={onCloseModal} center classNames={{ modal: "br-1" }}>
        <ContactForm buttonText={btnText} textFieldText="Write your message to us" formTitle="Request Your Personalized Demo Today!" />
      </Modal>

      {/* Hero Section */}
      <section className="mb-200">
        <div className="hero-section">
          <SectionWithImage
            triggerPopUp={(indexOfBtn) => onOpenModal(FirstSecImgData.buttons[indexOfBtn].text)}
            imageContainerCustomStyles={{ width: "100%" }}
            classNames="first-solutions-section"
            imageSrc={FirstSecImgData.imageSrc}
            imageAlt={FirstSecImgData.imageAlt}
            heading={FirstSecImgData.heading}
            paragraph={FirstSecImgData.paragraph}
            buttons={FirstSecImgData.buttons}
            imageOnLeft={FirstSecImgData.imageOnLeft}
            buttonClasses="hero-section-btns"
          />
        </div>
      </section>

      {/* Second Section (With Pinning) */}
      <section ref={secondSectionRef} className="trigger-scroll-section ">
        <div className="">
          <SectionWithImage
            classNames="full-screen-img"
            triggerPopUp={(indexOfBtn) => onOpenModal(SecondSecImgData.buttons[indexOfBtn].text)}
            imageSrc={SecondSecImgData.imageSrc}
            imageAlt={SecondSecImgData.imageAlt}
            heading={SecondSecImgData.heading}
            paragraph={SecondSecImgData.paragraph}
            buttons={SecondSecImgData.buttons}
            imageOnLeft={SecondSecImgData.imageOnLeft}
            buttonClasses="hero-section-btns"
          />
        </div>
      </section>

      {/* Third Section (With Pinning) */}
      <section ref={thirdSectionRef} className="trigger-scroll-section">
        <div className="">
          <SectionWithImage
            classNames="full-screen-img"
            triggerPopUp={(indexOfBtn) => onOpenModal(ThirdSecImgData.buttons[indexOfBtn].text)}
            imageSrc={ThirdSecImgData.imageSrc}
            imageAlt={ThirdSecImgData.imageAlt}
            heading={ThirdSecImgData.heading}
            paragraph={ThirdSecImgData.paragraph}
            buttons={ThirdSecImgData.buttons}
            imageOnLeft={ThirdSecImgData.imageOnLeft}
            buttonClasses="hero-section-btns"
          />
        </div>
      </section>

      {/* Fourth Section (With Pinning) */}
      <section ref={fourthSectionRef} className="trigger-scroll-section">
        <div className="">
          <SectionWithImage
            classNames="full-screen-img"
            triggerPopUp={(indexOfBtn) => onOpenModal(FourthSecImgData.buttons[indexOfBtn].text)}
            imageSrc={FourthSecImgData.imageSrc}
            imageAlt={FourthSecImgData.imageAlt}
            heading={FourthSecImgData.heading}
            paragraph={FourthSecImgData.paragraph}
            buttons={FourthSecImgData.buttons}
            imageOnLeft={FourthSecImgData.imageOnLeft}
            buttonClasses="hero-section-btns"
          />
        </div>
      </section>
      <section className="w-100 flex justify-content-center mt-600">
        <BeneficiariesSection/>
      </section>
      <section className="mt-500">
        <h1>Our Partners</h1>
          <PartnerCarousel />
      </section>
      <section className="w-100 flex justify-content-center px-1 my-2">
       <NotifyContainer boxColor="blue" onOpenModal={onOpenModal} text="Elevate your customer's experience like never before." btnText="Get in Touch"/>
      </section>
    </main>
    
  );
}

export default SolutionPage;
