import {BrowserRouter ,Routes,Route , useLocation,Navigate} from "react-router-dom"
import { useEffect ,useState} from "react";
// ---------importing componenets -------
import Header from './components/Header';
import Footer from './components/Footer';
// ------imprting pages ------
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import SolutionPage from './pages/SolutionPage'
import AboutPage from './pages/AboutPage';
import PartnerPage from './pages/PartnerPage';
import {CareerPage} from './pages/CareerPage';
// import BlogsPage from './pages/BlogsPage';
import ContactUsPage from './pages/ContactUsPage';
import StoriesPage from "./pages/StoriesPage";
// external libs for components 
import { ReactNotifications } from 'react-notifications-component' 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// assets 
import popupLandscape from "./assets/otherIcons/popupLandscape.png";
import popupPortrait from "./assets/otherIcons/popupPortrait.png";
import closeIcon from "./assets/otherIcons/close.svg";

// -------import styles ------
import "./styles/Global.css"
// ---css for external libs-- 
import 'react-notifications-component/dist/theme.css'
import 'animate.css';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [popUpOpen,setPopUpOpen] = useState(false);
  function togglePopUp(indexOfBtn){
    popUpOpen ? setPopUpOpen(false):setPopUpOpen(true);
  }
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  return (
    <BrowserRouter>
      {popUpOpen&&(
      isMobile?(
        <>
        <div className="popup-overlay">
        </div>
          <div className="popup-mobile ">
          <button className="popup-close-mobile" onClick={togglePopUp}>
            <LazyLoadImage effect="blur" src={closeIcon} alt={closeIcon} />
          </button>
            <LazyLoadImage effect="blur" className="popup-img" src={popupPortrait} alt={popupPortrait}/>
          </div>
        
        </>
      ):(
        <>
        <div className="popup-overlay">
        </div>
          <div className="popup">
          <button className="popup-close" onClick={togglePopUp}>
            <LazyLoadImage effect="blur" src={closeIcon} alt={closeIcon} />
          </button>
            <LazyLoadImage effect="blur" className="popup-img" src={popupLandscape} alt={popupLandscape}/>
          </div>  
        </>
      )
        
    )} 
      <ReactNotifications />
    <ScrollToTop>
      <Header popUpOpen={togglePopUp} />
        <Routes>
            <Route path="/" element={<HomePage popUpOpen={togglePopUp}/>} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/stories" element={<StoriesPage />} />
            <Route path="/solutions" element={<SolutionPage  />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/partner" element={<PartnerPage />} />
            <Route path="/career" element={<CareerPage />} />
            {/* <Route path="/career-description/:id" element={<CareerDescription/>} /> */}
            {/* <Route path="/blogs" element={<BlogsPage />} /> */}
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="*" element={<Navigate to='/' replace />} />  {/*if no path matches then redirect to / home path  */}
      </Routes>
      <Footer />
    </ScrollToTop>
  </BrowserRouter>

  );
}

export default App;
