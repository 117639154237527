import "../styles/components/NavDropDown.css";
import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation ,Link} from "react-router-dom";
export default function NavDropDown({menuName,subMenus={},customAnchorClass,handleMenuToggle,borderEnabled=false,isTab,isMobile}){
    const [isOpen,setIsOpen] =useState(false);
    const location = useLocation();
    // Compute the active state based on the current path
    const isCurrentPathActive = Object.values(subMenus).some(
        (nthPath) => location.pathname === nthPath
    );  
    const toggleHandler=()=>{
      setIsOpen(!isOpen);
    }
    return(
        <div className= "nav-drop-parent | relative">
        {/* if its tab or mobile then just making a dropdown btn a Button other wise just a div  */}
       {
        (isTab || isMobile)?
            <button onClick={toggleHandler} className={`nav-dropdown-btn ${isCurrentPathActive ? "active" : ""} | neutral-btn`}>
              <div className="nav-dropdown-txt">
              {menuName}
              {borderEnabled && 
                <div className="border-line"></div>
              }
                </div>
          </button> 
          :
          <div className={`nav-dropdown-btn ${isCurrentPathActive ? "active" : ""}`}>
          <div className="nav-dropdown-txt">
          {menuName}
          {borderEnabled && 
            <div className="border-line"></div>
          }
          </div>
        </div>
       }
       {/* if tab or movile them makeing toggle logic otherwise not (for desktop ) */}
        {
          (isTab || isMobile) ?
                (isOpen && 
                  <div className="nav-dropdown">
                <ul>
                  {
                      Object.entries(subMenus).map(([subMenuName,menuUrl])=>(
                          <li key={subMenuName}>
                          <Link to={menuUrl} className={`${customAnchorClass ?? ""} ${location.pathname === menuUrl?"active":""}`} onClick={handleMenuToggle}>
                          {subMenuName}
                          
                        </Link>
                        {borderEnabled && 
                          <div className="border-line"></div>
                        }
                        </li>
                      ))
                  }
                </ul>
                  </div>
                )
          :
          <div className="nav-dropdown">
          <ul>
            {
                Object.entries(subMenus).map(([subMenuName,menuUrl])=>(
                    <li key={subMenuName}>
                    <Link to={menuUrl} className={`${customAnchorClass ?? ""} ${location.pathname === menuUrl?"active":""}`} onClick={handleMenuToggle}>
                    {subMenuName}
                    
                  </Link>
                  {borderEnabled && 
                    <div className="border-line"></div>
                  }
                  </li>
                ))
            }
          </ul>
        </div>
        }
        </div>
    );
};
NavDropDown.propTypes = {
    menuName: PropTypes.string.isRequired,
    subMenus: PropTypes.object.isRequired,
    customAnchorClass: PropTypes.string,
    handleMenuToggle : PropTypes.func,
    borderEnabled: PropTypes.bool

}